import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardService } from '@sharedModule/service/card/card.service';
import { LocalStorageService } from '@sharedModule/service/local-storage/local-storage.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-card-response',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './add-card-response.component.html',
  styleUrl: './add-card-response.component.scss',
})
export class AddCardResponseComponent implements OnInit, OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly cardService = inject(CardService);
  private readonly localStorageService = inject(LocalStorageService);
  isCardAdded!: boolean;
  onDestroy$ = new Subject<boolean>();

  ngOnInit(): void {
    const setupIntentId =
      this.route.snapshot.queryParamMap.get('setup_intent') ?? '';
    const clientSecret =
      this.route.snapshot.queryParamMap.get('setup_intent_client_secret') ?? '';
    const sesionId = this.localStorageService.get('sesionId') ?? '';
    this.getAddCardResponse(sesionId, setupIntentId, clientSecret);
  }

  getAddCardResponse(
    sesionId: string,
    setupIntentId: string,
    clientSecret: string
  ): void {
    this.cardService
      .getAddCardResponse(sesionId, {
        setupIntentId,
        clientSecret,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: x => {
          if (x.success) {
            this.isCardAdded = true;
            this.localStorageService.removeBy('sesionId');
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
