import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public removeBy(key: string): void {
    localStorage.removeItem(key);
  }

  public checkKey(key: string): boolean {
    return this.get(key) ? true : false;
  }

  public clear(): void {
    localStorage.clear();
  }
}
