import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

export type Params =
  | HttpParams
  | {
      [param: string]:
        | string
        | string[]
        | number
        | number[]
        | boolean
        | boolean[];
    };

export type Headers =
  | HttpHeaders
  | {
      [header: string]: string | string[];
    };

export interface HttpOptions {
  headers?: Headers;
  observe?: 'body' | undefined;
  params?: Params;
  reportProgress?: boolean | undefined;
  responseType?: 'json';
  withCredentials?: boolean | undefined;
}

export abstract class BaseHttpClientService {
  protected _httpOption: HttpOptions = {
    headers: new HttpHeaders(),
  };

  protected _retryCount = 0;

  constructor(
    protected _httpClient: HttpClient,
    protected _baseUrl: string
  ) {
    this._baseUrl = _baseUrl;
    this._httpClient = _httpClient;
  }

  get<R>(url: string, params?: Params, retryCount?: number): Observable<R> {
    return this._httpClient
      .get<R>(`${this._baseUrl}/${url}`, {
        ...this._httpOption,
        params,
      })
      .pipe(this.retryRequest(retryCount));
  }

  post<T, R>(
    url: string,
    body?: T | null,
    params?: Params,
    retryCount?: number,
    header?: HttpOptions
  ): Observable<R> {
    return this._httpClient
      .post<R>(`${this._baseUrl}/${url}`, body, {
        ...this._httpOption,
        params,
        ...header,
      })
      .pipe(this.retryRequest(retryCount));
  }

  delete<R>(url: string, params?: Params, retryCount?: number): Observable<R> {
    return this._httpClient
      .delete<R>(`${this._baseUrl}/${url}`, { ...this._httpOption, params })
      .pipe(this.retryRequest(retryCount));
  }

  put<T, R>(
    url: string,
    body?: T | null,
    params?: Params,
    retryCount?: number
  ): Observable<R> {
    return this._httpClient
      .put<R>(`${this._baseUrl}/${url}`, body, { ...this._httpOption, params })
      .pipe(this.retryRequest(retryCount));
  }

  patch<T, R>(
    url: string,
    body: T | null,
    params: Params,
    retryCount?: number
  ): Observable<R> {
    return this._httpClient
      .patch<R>(`${this._baseUrl}/${url}`, body, {
        ...this._httpOption,
        params,
      })
      .pipe(this.retryRequest(retryCount));
  }

  private retryRequest = <T>(
    retryCount?: number
  ): MonoTypeOperatorFunction<T> =>
    retry<T>({ count: retryCount ?? this._retryCount, delay: 2000 });
}
