import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseHttpClientService } from './base-http-client.service';

const { BASE_URL } = environment;
@Injectable({
  providedIn: 'root',
})
export class HttpClientService extends BaseHttpClientService {
  constructor(httpClient: HttpClient) {
    super(httpClient, `${BASE_URL}`);
  }
}
