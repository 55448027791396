import { Routes } from '@angular/router';
import { AddCardResponseComponent } from '@feature/add-card-response/add-card-response.component';
import { AddCardComponent } from '@feature/add-card/add-card.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'add-card/error',
    pathMatch: 'full',
  },
  {
    path: 'add-card/:sessionId',
    component: AddCardComponent,
  },
  {
    path: 'add-card-response',
    component: AddCardResponseComponent,
  },
  {
    path: '**',
    redirectTo: 'add-card/error',
  },
];
