import { Injectable } from '@angular/core';
import { API_URL_CONSTANT } from '@sharedModule/constants/api-url.constant';
import { ClientSecretInterface } from '@sharedModule/models/card.model';
import { ResponseIdentity } from '@sharedModule/models/server-response-identity.model';
import { Observable } from 'rxjs';
import { HttpClientService } from '../http-client/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private httpClient: HttpClientService) {}

  getClientSecret(
    paymentSessionId: string
  ): Observable<ResponseIdentity<ClientSecretInterface>> {
    return this.httpClient.get<ResponseIdentity<ClientSecretInterface>>(
      API_URL_CONSTANT.getClientSecret + `/${paymentSessionId}`
    );
  }

  getAddCardResponse(
    paymentSessionId: string,
    payload: {
      setupIntentId: string;
      clientSecret: string;
    }
  ): Observable<ResponseIdentity<object>> {
    return this.httpClient.post(
      API_URL_CONSTANT.getAddCardResponse + `/${paymentSessionId}`,
      payload
    );
  }
}
