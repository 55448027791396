import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { ClientSecretInterface } from '@sharedModule/models/card.model';
import { CardService } from '@sharedModule/service/card/card.service';
import { LoaderService } from '@sharedModule/service/loader/loader.service';
import { LocalStorageService } from '@sharedModule/service/local-storage/local-storage.service';
import { MessageHandlerService } from '@sharedModule/service/message-handler/message-handler.service';
import {
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
  StripeElementsDirective,
  StripePaymentElementComponent,
  injectStripe,
} from 'ngx-stripe';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-card',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
  ],
  templateUrl: './add-card.component.html',
  styleUrl: './add-card.component.scss',
})
export class AddCardComponent implements OnInit, OnDestroy {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  private readonly route = inject(ActivatedRoute);
  private readonly cardService = inject(CardService);
  private readonly loaderService = inject(LoaderService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly messageHandlerService = inject(MessageHandlerService);

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
    },
  };

  paymentElementOptions: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        email: 'never',
        address: {
          country: 'never',
        },
      },
    },
  };

  readonly stripe = injectStripe(environment.STRIPE_PUBLIC_KEY);
  requestFrom!: string;
  paying = signal(false);
  isFormSubmitted = signal(false);
  cardHolderName = new FormControl('', [Validators.required]);
  cardHolderInfo!: ClientSecretInterface;
  onDestroy$ = new Subject<boolean>();

  ngOnInit() {
    this.requestFrom =
      this.route.snapshot.queryParamMap.get('requestFrom') ?? '';
    const sesionId = this.route.snapshot.paramMap.get('sessionId') ?? '';
    this.localStorageService.set('sesionId', sesionId);
    this.cardService
      .getClientSecret(sesionId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: x => {
          if (x.success) {
            this.elementsOptions.clientSecret = x.data.client_secret as string;
            this.cardHolderInfo = x.data;
          }
        },
      });
  }

  onAddCard() {
    if (this.paying() && this.cardHolderName.invalid) return;
    this.loaderService.isLoading.next(true);
    this.paying.set(true);
    this.isFormSubmitted.set(true);
    this.stripe
      .confirmSetup({
        elements: this.paymentElement.elements,
        confirmParams: {
          return_url:
            this.requestFrom === 'app'
              ? `${environment.HOST_URL}/add-card-response`
              : `${environment.PORTAL_URL}/dashboard/add-card-response`,
          payment_method_data: {
            billing_details: {
              email: this.cardHolderInfo.email,
              name: this.cardHolderName.value,
              address: {
                country: this.cardHolderInfo.country,
              },
            },
          },
        },
      })
      .subscribe(result => {
        this.paying.set(false);
        this.loaderService.isLoading.next(false);
        localStorage.setItem('result', JSON.stringify(result));
        if (result.error) {
          if (result.error.type !== 'validation_error')
            this.messageHandlerService.errorMessage(result.error.message);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
