import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

const DURATION_MS = 4_500;
@Injectable({
  providedIn: 'root',
})
export class MessageHandlerService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) {}

  errorMessage(
    errorMessage?: string,
    duration: number = DURATION_MS,
    horizontal: MatSnackBarHorizontalPosition = this.horizontalPosition,
    vertical: MatSnackBarVerticalPosition = this.verticalPosition
  ): void {
    if (errorMessage) {
      this.snackBar.open(errorMessage, 'Dismiss', {
        duration,
        panelClass: ['danger-snack-bar'],
        horizontalPosition: horizontal,
        verticalPosition: vertical,
      });
    }
  }

  show(
    message: string,
    action?: string,
    duration: number = DURATION_MS,
    horizontal: MatSnackBarHorizontalPosition = this.horizontalPosition,
    vertical: MatSnackBarVerticalPosition = this.verticalPosition
  ): void {
    if (message) {
      this.snackBar.open(message, action, {
        duration,
        panelClass: ['success-snack-bar'],
        horizontalPosition: horizontal,
        verticalPosition: vertical,
      });
    }
  }
}
