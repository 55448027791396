import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '@sharedModule/service/loader/loader.service';
import { finalize } from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoaderService);
  loadingService.isLoading.next(true);
  return next(req).pipe(
    finalize(() => {
      loadingService.isLoading.next(false);
    })
  );
};
