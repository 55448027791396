import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideNgxStripe } from 'ngx-stripe';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { errorInterceptor } from '@sharedModule/interceptors/error/error.interceptor';
import { loaderInterceptor } from '@sharedModule/interceptors/loader/loader.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideNgxStripe(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([errorInterceptor, loaderInterceptor])),
    CommonModule,
  ],
};
