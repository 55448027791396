<div class="add-card-wrapper">
  @if (elementsOptions.clientSecret) {
    <div class="input-wrapper">
      <label for="fname">Cardholder name</label>
      <input
        type="text"
        id="cardHolderName"
        name="cardHolderName"
        [ngClass]="{
          invalid: isFormSubmitted() && cardHolderName.invalid,
        }"
        [formControl]="cardHolderName" />
      <p *ngIf="isFormSubmitted() && cardHolderName.invalid">
        Your card number is incomplete.
      </p>
    </div>
    <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
      <ngx-stripe-payment [options]="paymentElementOptions" />
    </ngx-stripe-elements>
    <button (click)="onAddCard()">Add New Card</button>
  }
</div>
