import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageHandlerService } from '@sharedModule/service/message-handler/message-handler.service';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const unauthorized = 401;
  const messageHandlerService = inject(MessageHandlerService);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === unauthorized) {
          messageHandlerService.errorMessage(err.error.message);
        } else if (err.status === 0) {
          messageHandlerService.errorMessage(err.message);
        } else {
          messageHandlerService.errorMessage(err.error.message);
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
      }
      // Re-throw the error to propagate it further
      return throwError(() => err);
    })
  );
};
